import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "220px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.73619631901841%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADcklEQVQoz12Sa0xTZxjHDxAygkE+YEGcrRZKmdAyLLSKZawtKHbHgs5SbgUprIBcxRIvCBglzFCU1kaMWG1GvUTUatREkymoiRsz3j6gzIASdDo1wTmJgL2dv+fUywef5M17zv953l/+7/O8BOHnTyQ0XCCYIDsGA5g9WW0gJdW9fYLyIyc5y+vqGA2Ar4anWJ8mMxw1zie3HuGSjb3x2Yb2b8JjU4jPwZXrfLsgt80HY/GSRLLWgemqa0A+vaLUra9peQ6T45fv31Z2eRIq+zss6xxDZtcDpG4+D3m9DQJF/o4v0Lkilf+nzwhpo+Nx4RkPyq65ZtreApL65me0PmtO8frv6kYnPIaXoJQO74y4Yj+SS82uEtsjT4HlFqRluxEQGEQSwWFsv08w/wXZrQMJzbeQcXDCVXbbTR2m76nd3vKCzgXU2O3Vv72bhOHle+RYzzL33xUcSCyRb3ZMpxgcXpHOgpCIKCvhBnzAaGW9VdZ+E/LOYWqevBJk/6S7gwbmtTQ/p9Oh2sqKqGhhgpmT9H13BIdt9p1ZZVghrD6JxJoTrvj8XQgMDj3osxYpWdsYV2qFaMsAWIuz/wtbGH2o7gnlsjHAbU1PGSDxVcTnNedm7rntXG68h/Sd191caQ7jOovgSkjVD1UHkGUc9ArVTYzYFZPXXqS76cReGljctvMV3WAhAzkF+Hr9U4fNmGl/jdSuf5Cy/Q8sUOiZcwc+TpestqY1HENylf39Qk2nJ6bixBtpx5Cz8E8KpimKujA1jZZjR9/QpYlMfUmf43jtQ+CXq/BmGO9QoXEZ07S86Yt1trTAFl9sAb/QAtHG81hpGYWmz4WaQYraOuKkDgGUfmCQcaDU9F3ZsYdues1duBSmUWq+mPQEBM02p5X+Ko5L16aHRPJZRFjMUm1YbOp4uCD9YZRUPfKteM3fsaqGZ7nH//Xqb8C7ynzaHS6RDbGFcRu0/ZNTxf2A/qLTrbGOeSUbTrsW6XpmEteZoKjtAYubYPtsNIh5a/QKYX5mcUQFWd1jKLkIJKoN/zNDUXVd31d5A1BYnkPTO4HV3SNYbboL+ZZzSCrvgUi3F7MjeVeJtKZLXw+QECr1vGhZ0V/cH4vGWPwlv9OSH29lbS0/s3I8QvzzfXZKzjBn6ZphdjI5zElSPpgrkA3RdeP0szF9AG6ujRSNpSwSAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "220px Answer to Life",
          "title": "220px Answer to Life",
          "src": "/static/f237210c08daca50e82d92f8de86c548/c8042/220px-Answer_to_Life.png",
          "srcSet": ["/static/f237210c08daca50e82d92f8de86c548/222b7/220px-Answer_to_Life.png 163w", "/static/f237210c08daca50e82d92f8de86c548/c8042/220px-Answer_to_Life.png 220w"],
          "sizes": "(max-width: 220px) 100vw, 220px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h1>{`Les nombres sans contexte`}</h1>
    <p>{`Il existe un style de visualisation qui consiste à placer des nombres en gros caractères sur une page, comme des oeuvres d'art dans un musée, et de les commenter avec des petits panneaux de sous-titre. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.239263803680984%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHn2NZ3YQZ//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECMRASIf/aAAgBAQABBQKfSjRvMr//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAYEQACAwAAAAAAAAAAAAAAAAAAARESIf/aAAgBAgEBPwFqMFY//8QAGhAAAgIDAAAAAAAAAAAAAAAAARAAESExgf/aAAgBAQAGPwIa5LNYZX//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUFhwf/aAAgBAQABPyFWoaQWq6YowtODqHEZ8Z//2gAMAwEAAgADAAAAEGwP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARof/aAAgBAgEBPxDECCBHl//EABwQAAIDAAMBAAAAAAAAAAAAAAERADFxQVGxYf/aAAgBAQABPxBACKDqd+wBARETLXIidLTY1LbEto+S7PAn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "2017 iso figures fr",
          "title": "2017 iso figures fr",
          "src": "/static/7c96043495cb4f0f762811988c4a262f/6aca1/2017_iso_figures_fr.jpg",
          "srcSet": ["/static/7c96043495cb4f0f762811988c4a262f/d2f63/2017_iso_figures_fr.jpg 163w", "/static/7c96043495cb4f0f762811988c4a262f/c989d/2017_iso_figures_fr.jpg 325w", "/static/7c96043495cb4f0f762811988c4a262f/6aca1/2017_iso_figures_fr.jpg 650w", "/static/7c96043495cb4f0f762811988c4a262f/7c09c/2017_iso_figures_fr.jpg 975w", "/static/7c96043495cb4f0f762811988c4a262f/01ab0/2017_iso_figures_fr.jpg 1300w", "/static/7c96043495cb4f0f762811988c4a262f/12609/2017_iso_figures_fr.jpg 3000w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <center>
      <p>{`et`}</p>
    </center>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "560px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "169.32515337423314%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAiABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQX/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAH1tJkpmWrZrqhee3CaA//EABwQAAICAgMAAAAAAAAAAAAAAAERAAIDIRASE//aAAgBAQABBQJXas1eFwgvH6Cqu9zcZlsit2PIn//EABoRAAICAwAAAAAAAAAAAAAAAAABAhEQMTL/2gAIAQMBAT8BWqHA6Lz/AP/EABYRAAMAAAAAAAAAAAAAAAAAAAARIP/aAAgBAgEBPwEc/wD/xAAfEAABBAICAwAAAAAAAAAAAAABAAIRITFBECAiUXH/2gAIAQEABj8CN7RvSyjEygbUObJ+o+Vc5Ca331//xAAeEAEAAgICAwEAAAAAAAAAAAABABEhMUFRYXGBof/aAAgBAQABPyG6yhkUxVDYV7h0YPNOXEWrKi8xVx2RTv8ADGoNKvPcHm/kSX4Yi1Fo3CvIRDqUVqaT/9oADAMBAAIAAwAAABA/2D90D//EAB0RAAICAQUAAAAAAAAAAAAAAAERACEQMWGBobH/2gAIAQMBAT8QoyJKBfMAJGrXnUTeDXH/xAAbEQACAQUAAAAAAAAAAAAAAAAAAREQITFxof/aAAgBAgEBPxB3cmvDBDr/AP/EACEQAQACAgICAgMAAAAAAAAAAAERIQAxQXFRkWHBgaHR/9oACAEBAAE/EB3MfRetV5nCAgAcAWfrIV+h/uHEbEFoyB4IBQfxvAwIug56xVMm4kba1dYsyI3Df6yBQdLJTK4DpfeL8tKaB4g8VeQ0t+HGTI9YwWEhVawHA3n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "infographie sill entreprises 2016",
          "title": "infographie sill entreprises 2016",
          "src": "/static/fafebdfcd27cca56a3db9b68dc6c0e8d/9342c/infographie-sill-entreprises-2016.jpg",
          "srcSet": ["/static/fafebdfcd27cca56a3db9b68dc6c0e8d/d2f63/infographie-sill-entreprises-2016.jpg 163w", "/static/fafebdfcd27cca56a3db9b68dc6c0e8d/c989d/infographie-sill-entreprises-2016.jpg 325w", "/static/fafebdfcd27cca56a3db9b68dc6c0e8d/9342c/infographie-sill-entreprises-2016.jpg 560w"],
          "sizes": "(max-width: 560px) 100vw, 560px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <center>
      <p>{`ou encore`}</p>
    </center>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.16564417177914%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAals0a82w//EABoQAQADAAMAAAAAAAAAAAAAAAIAAQMREyH/2gAIAQEAAQUCOvqDV1k7nFR52n0qf//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGorP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIBBQAAAAAAAAAAAAAAAAABQRARISJx/9oACAEBAAY/Atmzs1ungg//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMUFRkf/aAAgBAQABPyEheB7uWMDjWS0qsaZ2d2LAbMiitn7P/9oADAMBAAIAAwAAABC0z//EABkRAQACAwAAAAAAAAAAAAAAAAEAESFBof/aAAgBAwEBPxAZsxDY8n//xAAXEQEBAQEAAAAAAAAAAAAAAAABACER/9oACAECAQE/EOZsAX//xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMUGRof/aAAgBAQABPxB+B5eiP5LEbZ1ZV55yV17rFN+RFLSvd43Boeit4QOXHgqf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "is infographie vendeeglobe 17janv2017 final",
          "title": "is infographie vendeeglobe 17janv2017 final",
          "src": "/static/4bfb8f11d44967539b3ca13db480335f/6aca1/is_infographie_vendeeglobe_17janv2017_final.jpg",
          "srcSet": ["/static/4bfb8f11d44967539b3ca13db480335f/d2f63/is_infographie_vendeeglobe_17janv2017_final.jpg 163w", "/static/4bfb8f11d44967539b3ca13db480335f/c989d/is_infographie_vendeeglobe_17janv2017_final.jpg 325w", "/static/4bfb8f11d44967539b3ca13db480335f/6aca1/is_infographie_vendeeglobe_17janv2017_final.jpg 650w", "/static/4bfb8f11d44967539b3ca13db480335f/7c09c/is_infographie_vendeeglobe_17janv2017_final.jpg 975w", "/static/4bfb8f11d44967539b3ca13db480335f/01ab0/is_infographie_vendeeglobe_17janv2017_final.jpg 1300w", "/static/4bfb8f11d44967539b3ca13db480335f/fb69b/is_infographie_vendeeglobe_17janv2017_final.jpg 3579w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`En général ces gros chiffres sont une manière de ne `}<strong parentName="p">{`pas`}</strong>{` mettre la grandeur dans son contexte. Et quoi de moins informatif qu'un nombre tout seul sans rien autour. Quel que soit le sujet, le lecteur néophyte ou expert a au moins le `}<strong parentName="p">{`droit`}</strong>{` de savoir si ce nombre est:`}</p>
    <ul>
      <li parentName="ul">{`beaucoup ou peu ?`}</li>
      <li parentName="ul">{`à quoi doit il être comparé ?`}</li>
      <li parentName="ul">{`en augmentation ou en diminution par rapport à la période précédente ?`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <p>{`A moins d'avoir ces réponses minimales, il parait hasardeux de se forger un avis de niveau supérieur: faut il s'inquiéter ou se réjouir ? Y a t il une décision à prendre ? `}</p>
    <p>{`En résumé: `}<strong parentName="p">{`Que faut-il en penser ?`}</strong>{` `}</p>
    <p>{`Un exemple brillantissime est présent dans le livre de `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Factfulness-Reasons-World-Things-Better/dp/1250107814"
      }}>{`Hans Rosling FACTFULNESS`}</a>{`: `}</p>
    <center>
      <p><strong parentName="p">{`4 millions de bébé morts nés l'an dernier`}</strong></p>
    </center>
    <p>{`Le chiffre est tellement énorme qu'il est impossible de réfléchir ou de relativiser. `}</p>
    <p>{`La mise en contexte change radicalement la perception: Il suffit de savoir qu'en 1950 le nombre de bébé morts nés était de 14 millions au niveau mondial. Autrement dit 10 millions de bébés ont été sauvés l'an dernier comparé à 1950 !!! (soit 1% de mortalité en 2018 en net progrès depuis les 15% de 1950).`}</p>
    <p>{`Soudain le chiffre de 4 millions qui paraissait `}<strong parentName="p">{`énorme`}</strong>{` devient ridiculement `}<strong parentName="p">{`petit`}</strong>{` et la situation encourageante.`}</p>
    <hr></hr>
    <p>{`L'illustration `}<strong parentName="p">{`42`}</strong>{` de titre provient de `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Phrases_from_The_Hitchhiker%27s_Guide_to_the_Galaxy#Answer_to_the_Ultimate_Question_of_Life,_the_Universe,_and_Everything_42"
      }}>{`The Hitchhiker's Guide to the Galaxy`}</a>{` ou `}<strong parentName="p">{`H2G2`}</strong>{` du génial `}<strong parentName="p">{`Douglas Adams`}</strong>{`.`}</p>
    <br />
    <p><em parentName="p">{`Quarante-deux ! cria Loonquawl. Et c'est tout ce que t'as à nous montrer au bout de sept millions et demi d'années de boulot ?
— J'ai vérifié très soigneusement, dit l'ordinateur, et c'est incontestablement la réponse exacte. Je crois que le problème, pour être tout à fait franc avec vous, est que vous n'avez jamais vraiment bien saisi la question.`}</em></p>
    <p><em parentName="p">{`Six by nine. Forty two.
That's it. That's all there is.
I always thought something was fundamentally wrong with the universe`}</em></p>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      